<template>
  <div>
    <v-dialog v-model="$store.state.dialog2" width="1200">
      <v-card flat class="card">
        <v-toolbar outlined flat color="grey lighten-4">
          <v-toolbar-title>
            <h3 v-if="$store.state.itemDetails != ''">{{ $store.state.itemDetails.node.name }}</h3>
          </v-toolbar-title>
          <v-spacer />
          <v-btn class="d-none" color="primary" v-print="printObj">
            طباعة
          </v-btn>
          <v-btn @click="close()" icon>
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>

        <div id="print">
          <v-container>
            <v-card outlined>
              <v-container fluid v-if="$store.state.itemDetails != ''">
                <h3>
                  {{ $t("mtkhth-alijraa") }}:
                  {{ $store.state.itemDetails.user.fullName }}
                </h3>
                <h3>
                  {{ $t("alanwan-alwzyfy") }}:
                  {{ $store.state.itemDetails.user.jobTitle }}
                </h3>
              </v-container>
            </v-card>
          </v-container>
          <Loading v-if="loading" />
          <v-form @submit.prevent="submit()" ref="form" v-if="!loading">
            <v-container>
              <v-card outlined>
                <v-container>
                  <v-row>
                    <template v-for="(item, index) in items">
                      <v-col :cols="item.field.fieldType.name == 'Multiple Files' ? 4 :  item.formCols" :key="index" v-if="item.data != null">
                        <div
                          v-if="
                            item.field.fieldType.name == 'text box' ||
                            item.field.fieldType.name == 'Date and time' || 
                            item.field.fieldType.name == 'Html editor'
                          "
                        >
                          <h4>{{ item.field.title }}:</h4>
                          <v-text-field
                            class="mt-2"
                            hide-details
                            disabled
                            v-model="item.data"
                            outlined
                          ></v-text-field>
                        </div>
  
                        <!-- data-table start -->
                        <div v-if="item.field.fieldType.name == 'Table'">
                          <h4 class="mb-2">
                            {{ item.field.title }}
                            <span class="required" v-if="item.isRequired">*</span>
                          </h4>
                          <v-data-table
                            :items="tableItems"
                            :headers="tableHeaders"
                            hide-default-footer
                            :no-data-text="$t('no-data')"
                          />
                        </div>
                        <!-- data-table end -->
  
                        <!-- file start -->
                        <div v-if="item.field.fieldType.name == 'File'">
                          <h4 class="mb-2">
                            {{ item.title }}
                            <span class="required" v-if="item.isRequired">*</span>
                          </h4>
                          <v-btn
                            @click="
                              fileExt(item.data) == 'jpg' ||
                              fileExt(item.data) == 'png' ||
                              fileExt(item.data) == 'jpeg' ||
                              fileExt(item.data) == 'gif' ||
                              fileExt(item.data) == 'fav' ||
                              fileExt(item.data) == 'ico' ||
                              fileExt(item.data) == 'pdf'
                                ? view(item.data)
                                : downloadURI(
                                    $config.BASE_URL_PROD + '/' + item.data,
                                    item.title
                                  )
                            "
                            class="mr-2"
                            color="teal"
                            outlined
                            cols="12"
                            md="3"
                          >
                            <h3>{{ item.field.title }}</h3>
                            <v-spacer />
                            <v-icon class="mr-2">cloud_download</v-icon>
                          </v-btn>
                        </div>
                        <!-- file end -->
  
                        <!-- multiple file start -->
                        <div v-if="item.field.fieldType.name == 'Multiple Files'">
                          <h4 class="mb-2">
                            {{ item.field.title }} :
                          </h4>
                          <template v-for="(file, index) in item.data.split(',')">
                            <img
                              @click="view(file)"
                              :src="$config.BASE_URL_PROD + '/' + file"
                              :alt="file.split('/').pop()"
                              v-if="
                                fileExt(file) == 'jpg' ||
                                fileExt(file) == 'png' ||
                                fileExt(file) == 'jpeg' ||
                                fileExt(file) == 'gif' ||
                                fileExt(file) == 'fav' ||
                                fileExt(file) == 'ico'
                                
                              "
                              :key="'img' + index"
                              style="object-fit: contain;height:150px"
                            />
                            <v-btn
                              v-else
                              :key="'dl' + index"
                              @click=" downloadURI(
                                    $config.BASE_URL_PROD + '/' + file,
                                    item.title
                                  )
                            "
                              class="mr-2"
                              color="teal"
                              outlined
                              large
                            >
                              <v-icon class="ml-2">cloud_download</v-icon>
                              <h3>تحميل</h3>
                            </v-btn>
                          </template>
                        </div>
                        <!-- multiple end -->
  
                        <!--checkbox start -->
                        <div v-if="item.field.fieldType.name == 'Check box'">
                          <h4 class="mb-2">
                            {{ item.field.title }}
                            <span class="required" v-if="item.isRequired">*</span>
                          </h4>
                          <v-row>
                            <v-col cols="1" v-for="check in item.field.value.split(',')" :key="check">
                              <div class="d-flex align-center">
                                <input
                                  type="checkbox"
                                  :checked="
                                    item.data.split(',').indexOf(check) > -1
                                  "
                                  class="mx-1"
                                  hide-details="auto"
                                />
                                <h4>{{ check }}</h4>
                              </div>
                            </v-col>
                          </v-row>
                        </div>
                        <!--checkbox end -->
                        <!-- Single select start -->
                        <div v-if="item.field.fieldType.name == 'Single select'">
                          <h4 class="mb-2">
                            {{ item.field.title }}
                            <span class="required" v-if="item.isRequired">*</span>
                          </h4>
                          <v-autocomplete
                            :no-data-text="$t('no-data')"
                            :value="item.data"
                            :placeholder="item.field.title"
                            filled
                            :items="item.field.value.split(',')"
                            outlined
                            disabled
                            hide-details="auto"
                            required
                          />
                        </div>
                        <!-- Single select end -->
                        <!-- Multiple select start -->
                        <div v-if="item.field.fieldType.name == 'Multiple select'">
                          <h4 class="mb-2">
                            {{ item.field.title }}
                            <span class="required" v-if="item.isRequired">*</span>
                          </h4>
                          <v-autocomplete
                            multiple
                            chips
                            :no-data-text="$t('no-data')"
                            :value="item.data.split(',')"
                            :placeholder="item.field.title"
                            filled
                            outlined
                            disabled
                            :items="item.field.value.split(',')"
                            hide-details="auto"
                            required
                          />
                        </div>
                        <div v-if="item.field.fieldType.name == 'Signature'">
                          <h4 class="mb-2">
                            {{ item.field.title }}
                            <span class="required" v-if="item.isRequired">*</span>
                          </h4>
                          <v-img
                            :src="$config.BASE_URL_PROD + '/' + item.data"
                            :alt="item.field.title"
                            contain
                          />
                        </div>
                        <div
                          v-if="item.field.fieldType.name == 'Large File'"
                          class="d-flex align-center"
                        >
                          <h4 class="mb-2">
                            {{ item.field.title }} :
                            <span class="required" v-if="item.isRequired">*</span>
                          </h4>
                          <v-btn
                            class="mx-2"
                            color="success"
                            :href="$config.BASE_URL_PROD + '/' + item.data"
                            target="_blank"
                          >
                            <v-icon>mdi-eye</v-icon>
                            <span>{{ $t("ardh-almlf") }}</span>
                          </v-btn>
                        </div>
                        <!-- Multiple select end -->
                      </v-col>
                    </template>
                  </v-row>
                </v-container>
              </v-card>
            </v-container>
          </v-form>
        </div>
      </v-card>
      <v-dialog width="900" v-model="viewDialog">
        <v-card>
          <v-card-title>
            عرض الصورة
            <v-spacer></v-spacer>
            <v-btn color="error" text @click="viewDialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text>
            <img
              :src="$config.BASE_URL_PROD + '/' + file"
              :alt="file.split('/').pop()"
              v-if="
                fileExt(file) == 'jpg' ||
                fileExt(file) == 'png' ||
                fileExt(file) == 'jpeg' ||
                fileExt(file) == 'gif' ||
                fileExt(file) == 'fav' ||
                fileExt(file) == 'ico'
              "
              style="object-fit: contain; width: 100%;height:500px"
            />
            <pdf :src="$config.BASE_URL_PROD + '/' + file" v-if="fileExt(file) == 'pdf'"></pdf>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-dialog>
  </div>
</template>

<script>
import pdf from "vue-pdf";

export default {
  name: "view-form",
  components: {
    pdf
  },
  data() {
    return {
      printObj: {
              id: "print",
              popTitle: "طباعة المعاملة",
              extraCss: "https://cdn.bootcdn.net/ajax/libs/animate.css/4.1.1/animate.compat.css, https://cdn.bootcdn.net/ajax/libs/hover.css/2.3.1/css/hover-min.css",
              extraHead: '<meta http-equiv="Content-Language"content="zh-cn"/>',
              beforeOpenCallback (vue) {
                vue.printLoading = true
                console.log('打开之前')
              },
              openCallback (vue) {
                vue.printLoading = false
                console.log('执行了打印')
              },
              closeCallback (vue) {
                console.log('关闭了打印工具')
              }
            },
      items: [],
      loading: false,
      tableHeaders: [],
      viewDialog: false,
      file: "",
      tableItems: []
    };
  },
  mounted() {
    this.reset();
    this.$eventBus.$on("fill-fields", () => {
      this.reset();

      this.getItems();
      console.log(this.$store.state.itemDetails);
    });
    // const multiplesOf = (numbers, number) => numbers.filter(n => !(n % number));
    // console.log(multiplesOf([0,1,2,3,4,5,6,7], 7));
  },
  methods: {
    fileExt(file) {
      return file
        .split(".")
        .pop()
        .toLowerCase();
    },
    view(file) {
      if (
        this.fileExt(file) == "jpg" ||
        this.fileExt(file) == "png" ||
        this.fileExt(file) == "jpeg" ||
        this.fileExt(file) == "gif" ||
        this.fileExt(file) == "fav" ||
        this.fileExt(file) == "ico"
      ) {
        this.file = file;
        this.viewDialog = true;
      } else {
        window.open(this.$config.BASE_URL_PROD + "/" + file, "_blank");
      }
    },
    reset() {
      this.items = [];
      this.tableHeaders = [];
      this.tableItems = [];
    },
    submit() {
      if (this.$store.state.itemDetails.dialogType == "add") {
        this.addItem();
      } else {
        this.editItem();
      }
    },
    downloadURI(url, filename) {
      fetch(url)
        .then(response => response.blob())
        .then(blob => {
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = filename;
          link.click();
        })
        .catch(console.error);
    },
    async getItems() {
      this.loading = true;

      try {
        const res = await this.$http.get(
          `OrderData?OrderNodeId=${this.$store.state.itemDetails.id}&IsDeleted=false&PageIndex=1&PageSize=100`
        );
        this.items = res.data.result;

        for (let i = 0; i < this.items.length; i++) {
          if (this.items[i].field.fieldType.name == "Table") {
            // sort table data
            this.items[i].field.fieldChildren.sort((a, b) =>
              a.sequence > b.sequence ? 1 : -1
            );

            // arrange table header
            for (let j = 0; j < this.items[i].field.fieldChildren.length; j++) {
              this.tableHeaders.push({
                text: this.items[i].field.fieldChildren[j].field.title,
                value: this.items[i].field.fieldChildren[j].sequence.toString()
              });
            }

            // get all rows values of table to use it in tableItems
            let rowsKey = [
              ...new Set(
                this.items[i].orderDataChildren.map(function(item) {
                  return item.row;
                })
              )
            ];

            // arrange all rows as keys and it's records as object values
            var rows = this.items[i].orderDataChildren,
              result = rows.reduce(function(r, a) {
                r[a.row] = r[a.row] || [];
                r[a.row].push(a);
                return r;
              }, Object.create(null));

            // transfer all un ordered rows key to ordered array
            let new_arr = [];
            for (let a = 0; a < rowsKey.length; a++) {
              new_arr[a] = result[rowsKey[a]];
            }

            // arrange table data and set every row separately
            for (let k = 0; k < new_arr.length; k++) {
              this.getRow(new_arr[k]).then(res => {
                // console.log(res)
                this.tableItems.push(res);
              });
            }
          }
        }
      } catch (err) {
        console.log(err);
      } finally {
        this.loading = false;
      }
    },

    async getRow(item) {
      console.log("item");
      console.log(item);
      const arr = [];
      for (let i = 0; i < item.length; i++) {
        arr.push(
          [item[i].fieldChild.sequence, item[i].data],
          ["fieldChildId", item[i].fieldChildId],
          ["row", item[i].row]
        );
      }
      return Object.fromEntries(arr);
    },

    async close() {
      await this.$store.commit("setItemDetails", "");
      await this.$store.commit("setDialog2");
      this.reset();
      this.resetValidation();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    }
  },
  beforeDestroy() {
    this.$eventBus.$off("fill-fields");
  }
};
</script>
<style>
@media print {
  * {
    -webkit-print-color-adjust: exact;
    direction: rtl;
  }
  @page {
    size: A4;
    margin: 0;
  }
}
</style>